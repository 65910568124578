import React, { useEffect, useState, Suspense, ComponentType, useRef } from 'react';

const ExternalModuleComponent = (props: any) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  const [ModuleComponent, setModuleComponent] = useState<ComponentType | null>(null);
  useEffect(() => {
    const importScript = async () => {
      try {
        //@ts-ignore
        await import('https://s3.glbimg.com/v1/AUTH_cae15c1f760d418f9f8a1f3193d715cd/wc-vitrine/vitrine-latest-preview.js');

        setLoaded(true);
      } catch (error) {
        console.error('Failed to load external module', error);
      }
    };

    importScript();

    return () => {
    };
  }, [props]);

  useEffect(() => {
    if (loaded && containerRef.current) {
      containerRef.current.innerHTML = '';

      const customElement = document.createElement('vitrine-shoppable');

      customElement.setAttribute('template', 'GAM');
      customElement.setAttribute('hideCta', 'true');
      customElement.setAttribute('sellerBranding', 'true');
      customElement.setAttribute('logoImg', 'https://s3.glbimg.com/v1/AUTH_cae15c1f760d418f9f8a1f3193d715cd/logos-anunciantes/vitrine_globo.svg');
      customElement.setAttribute('hidePagination', 'true');
      customElement.setAttribute('campaign', '');
      customElement.setAttribute('horizontal', 'true');
      customElement.setAttribute('appEnv', 'qa');
      customElement.setAttribute('sellerName', 'Globoplay');
      customElement.setAttribute('objectFit', 'contain');
      customElement.setAttribute('creativeHeight', '250');
      customElement.setAttribute('preview', 'true');
      customElement.setAttribute('campaignObj', JSON.stringify(props?.props));
      customElement.setAttribute('animateSeconds', '0');

      containerRef.current.appendChild(customElement);
    }
  }, [props, loaded]);

  return (
    <>
      {props?.props?.code && <h1 style={{ fontSize: '20px' }}>Preview da campanha {props?.props?.code}</h1>}
      <div ref={containerRef}>
        {!loaded && <p>Loading external module...</p>}
      </div>
    </>

  );
};

export default ExternalModuleComponent;
