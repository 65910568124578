const baseUrl = '/api/';
const basePath = window.location.origin

const headers = {
    "Content-Type":"application/json"
};

const handleResponse = async (response) => {
    if (response.status === 401) {
        window.location.replace("/unauthorized");
    }
    return response;
};

export const ApiService = {
    async get(endpoint, search={}){
        const {id} = search;
        const res = await fetch(`${basePath}${baseUrl}${endpoint}${id?`/${id}`:''}`,{headers})
        .then(response => handleResponse(response));

        if (!res.ok) {
            const {status, statusText} = res;
            const resData = await res.json();
            return {status, error:resData.error||statusText, data:resData};
        }
        const resData =await res.json();
        return {data:resData};  
    },
    async post(endpoint, data){
        const res = await fetch(`${basePath}${baseUrl}${endpoint}`, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        })
        .then(response => handleResponse(response));
        if (!res.ok) {
            const {status, statusText} = res;
            const resData = await res.json();
            return {status, error:resData.error||statusText, data:resData};
        }
        const resData =await res.json();
        return {data:resData};    
    },
    async patch(endpoint, data, search={}){
        const {id} = search;
        const res = await fetch(`${basePath}${baseUrl}${endpoint}${id?`/${id}`:''}`, {
            method: 'PATCH',
            headers,
            body: JSON.stringify(data)
        })
        .then(response => handleResponse(response));

        if (!res.ok) {
            const {status, statusText} = res;
            const resData = await res.json();
            return {status, error:resData.error||statusText, data:resData};
        }
        const resData =await res.json();
        return {data:resData};   
    },
    async delete(endpoint, id){
        const res = await fetch(`${basePath}${baseUrl}${endpoint}/${id}`, {
            method: 'DELETE',
            headers
        })
        .then(response => handleResponse(response));

        if (!res.ok) {
            const {status, statusText} = res;
            const resData = await res.json();
            return {status, error:resData.error||statusText, data:resData};
        }
        const resData =await res.json();
        return {data:resData};   
    }
}