import { ApiService } from './ApiService';
import endpoints from "./endpoint";

const API = {
    async one(endpoint, search={}){
        return await ApiService.get(endpoint, search);
    },
    async list(endpoint){
        return await ApiService.get(endpoint);
    },
    async create(endpoint,data){
        return await ApiService.post(endpoint, data);
    },
    async update(endpoint,data, search={}){
        return await ApiService.patch(endpoint, data, search);
    },
    async remove(endpoint, search={}){
        return await ApiService.delete(endpoint, search);
    }
}

export {endpoints};
export default API;

