import React, {useState} from "react";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import ConfirmationNumberRoundedIcon from "@mui/icons-material/ConfirmationNumberRounded";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { flags } from "../../../services/flags";
import Edit from "@mui/icons-material/Edit";

type Item = {
  id:string|number;
  code:string;
  title?:string;
  name?:string;
  link:string;
}

type Items = Item[]; 

type SearchListItemsProps={
  flag:string;
  itemList:Items;
  removeItem:(index:number)=>void;
  editItem:(item:Item, index:number)=>void;
}

const ScrollableList = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  maxHeight: "300px",
  overflowY: "auto",
}));

export default function SearchListItems({itemList, removeItem, flag, editItem}:SearchListItemsProps) {
  const itemsItems = (list: Items)=>{
    if (list.length>0) {
      return list.map((item,index)=>(
        <>
          <ListItem
            alignItems="flex-start"
            secondaryAction={
              <>
                <IconButton edge="end" aria-label="edit" onClick={()=>editItem(item,index)}>
                <Edit/>
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={()=>removeItem(index)}>
                <DeleteIcon />
              </IconButton>
              </>
            }
          >
            <ListItemAvatar>
              <Avatar>
                {flag===flags.COUPONS?<ConfirmationNumberRoundedIcon />:<ProductionQuantityLimitsIcon />}
              </Avatar>
              {/*<ListItemAvatar>
                      <Avatar alt="" src="" />
                    </ListItemAvatar>*/}
            </ListItemAvatar>
            <ListItemText
              primary={
                <Link
                  href={item.link}
                  underline="none"
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.code}
                </Link>
              }
              secondary={item.title||item.name}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </>
      ))
    }
    return (
      <Typography align="center" variant="overline" display="block" gutterBottom>
        {`Adicione ${flag===flags.COUPONS?"cupons":"produtos"} à esta campanha...`}
      </Typography>
    )
  }

  return (
    <ScrollableList>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {itemsItems(itemList)}
      </List>
    </ScrollableList>
  );
}
