import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Backdrop, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import API, { endpoints } from '../../../services/api/API';

interface coupon {
    id: number;
    code: string;
    title: string;
    type: string;
}

function Listcoupon() {
    const [coupons, setcoupons] = useState<coupon[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [couponToDelete, setcouponToDelete] = useState<coupon | null>(null);

    useEffect(() => {
        fetchcoupons();
    }, []);

    const fetchcoupons = async () => {
        setLoading(true);
        const { error, data } = await API.list(endpoints.COUPONS);
        if (!error) {
            setcoupons([...data]);
        }
        setLoading(false);
    };

    const deletecoupon = async (couponId: number) => {
        setLoading(true);
        const { error, data } = await API.remove(endpoints.COUPONS, couponId);
        if (!error) {
            fetchcoupons();
        }
        setLoading(false);
    };

    const handleDeleteClick = (coupon: coupon) => {
        setcouponToDelete(coupon);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (couponToDelete) {
            await deletecoupon(couponToDelete.id);
            setcouponToDelete(null);
            setDeleteDialogOpen(false);
        }
    };

    const handleDeleteCancel = () => {
        setcouponToDelete(null);
        setDeleteDialogOpen(false);
    };

    const rendercoupons = () => coupons.map((coupon) => (
        <ListItem key={coupon.id}>
            <ListItemText
                primary={coupon.title}
                secondary={`Código: ${coupon.code}, Tipo: ${coupon.type}`}
            />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit">
                    <Link to={`/coupons/${coupon.id}/edit`}>
                        <EditIcon />
                    </Link>
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteClick(coupon)}>
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    ))

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <h1>Cupons</h1>
                <Button variant="outlined" color="primary" component={Link} to="/coupons/create">
                    Cadastrar Novo
                </Button>
            </Box>
            <List>
                {rendercoupons()}
            </List>

            <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
                <DialogTitle>{"Excluir Cupom"}</DialogTitle>
                <DialogContent>
                    <p>Você realmente deseja excluir este cupom?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>

            <Backdrop open={loading} style={{ zIndex: 1000 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default Listcoupon;
