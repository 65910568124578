import React, { useEffect, useRef, useState } from 'react';
import * as _ from "lodash";

const CuponsComponent = (props: any) => {
  const [campaign, setCampaign] = useState()
  const vitrineCuponsRef = useRef(null);
  const importScript = async () => {
    try {
      const {
        head
      } = document;

      const script = document.createElement("script");
      script.async = true;
      script.src = `https://s3.glbimg.com/v1/AUTH_cae15c1f760d418f9f8a1f3193d715cd/wc-vitrine-cupom/main.8ad3de2e-preview.js`;
      head.insertBefore(script, head.firstChild);

      //@ts-ignore
      // await import('https://s3.glbimg.com/v1/AUTH_cae15c1f760d418f9f8a1f3193d715cd/wc-vitrine-cupom/main.8ad3de2e-preview.js');

    } catch (error) {
      console.error('erro ao carregar modulo da vitrine', error);
    }
  };
  useEffect(() => {
    importScript()

    console.log('primeira renderização')
    //@ts-ignore
    return () => {
      console.log('alo, saindo do componente')
      var vitrineCupons = document.querySelector('vitrine-cupons');

      if (vitrineCupons) {
        //@ts-ignore
        vitrineCupons.parentNode.removeChild(vitrineCupons);
      }
    }
  }, [])
  useEffect(() => {
    if (props.props) {
      setCampaign(props?.props);
    }


  }, [props.props, props]);


  return (
    <div>
      {campaign &&
        //@ts-ignore
        <h1 style={{ fontSize: '20px' }}>Preview da campanha {campaign?.code}</h1>
      }
      {campaign && <vitrine-cupons

        preview='true'
        campaignObj={JSON.stringify(props?.props) || "{}"}
        appEnv='qa'
      ></vitrine-cupons>

      }
    </div>
  );
};

export default CuponsComponent;

