import React, { useState, useEffect } from "react";
import API, { endpoints } from "../../../services/api/API";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Alert,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {METHODS} from '../../../enums/methods'
import VitrineShoppable from "../wc/vitrineShoppable"
const initialProducts = {
  code: "",
  name: "",
  link: "",
  shortUrl: "",
  description: "",
  image: "",
  imageSecondary: "",
  video: "",
  originalPrice: "",
  actualPrice: "",
  installmentPrice: "",
  installmentNro: "",
  discount: "",
  sku: "",
  active: 1,
  category: "",
  quantity: "",
  supplier: "",
  productNote: "",
  dimension: "",
  sellerId: "",
};
type ItemSeller = {
  id: string | number;
  cnpj: String;
  originalName: String | null;
};

type OptionSeller = {
  label: string;
  value: string;
};

type AllOptionSellers = OptionSeller[];

function CreateProduct({method}: any) {
  const schema = Yup.object().shape({
    code: Yup.string().required("Codigo é obrigatório"),
    name: Yup.string().required("Titulo é obrigatório"),
    link: Yup.string()
      .url("URL não  é inválido")
      .required("URL não  é inválido"),
    shortUrl: Yup.string()
      .url("URL não  é inválido")
      .required("URL não  é inválido"),
    description: Yup.string(),
    image: Yup.string()
      .url("URL não  é inválido")
      .required("URL não  é inválido"),
    imageSecondary: Yup.string()
      .url("URL não  é inválido").nullable(),
    video: Yup.string()
      .url("URL não  é inválido").nullable(),
    originalPrice: Yup.string().nullable(),
    actualPrice: Yup.string().required(),
    installmentPrice: Yup.string().nullable(),
    installmentNro: Yup.string().nullable(),
    discount: Yup.string().nullable(),
    sku: Yup.string().nullable(),
    category: Yup.string().nullable(),
    quantity: Yup.string().nullable(),
    supplier: Yup.string().nullable(),
    productNote: Yup.string().nullable(),
    dimension: Yup.string().nullable(),
    sellerId: Yup.string().required("Anunciante é obrigatório"),
  });

  const [product, setProduct] = useState({ ...initialProducts });
  const [productError, setProductError] = useState({ ...initialProducts });
  const [resMessage, setResMessage] = useState<string>("");
  const [resType, setResType] = useState("warning");
  const [allSelles, setAllSelles] = useState<AllOptionSellers>([]);
  const [allTypes, setAllTypes] = useState([
    {
      value: "voucher",
      label: "Voucher",
    },
  ]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { productId } = useParams();
  useEffect(() => {
    setProduct({ ...initialProducts });
    setProductError({ ...initialProducts });
    const loadProduct = async () => {
      try {
        if(method === METHODS.update){
          const { error, data } = await API.one(endpoints.PRODUCTS, {
            id: productId,
          });
          if (error || data.length < 1) {
            throw new Error(error);
          }
          const {
            code,
            name,
            link,
            shortUrl,
            description,
            image,
            imageSecondary,
            video,
            originalPrice,
            actualPrice,
            installmentPrice,
            installmentNro,
            discount,
            sku,
            category,
            quantity,
            supplier,
            productNote,
            dimension,
          } = data;
          const { id: seller } = data?.seller;
          //@ts-ignore
          setProduct({
            code,
            name,
            link,
            shortUrl,
            description,
            image,
            imageSecondary,
            video,
            originalPrice,
            actualPrice,
            installmentPrice,
            installmentNro,
            discount,
            sku,
            category,
            quantity,
            supplier,
            productNote,
            dimension,
            sellerId: seller,
          });
        }
       
        const loadOptionsSellers = async () => {
          try {
            const { error, data } = await API.list(endpoints.SELLERS);
            if (error || data.length < 1) {
              throw new Error(error);
            }
            const optionSeller: AllOptionSellers = data.map(
              (item: ItemSeller) => ({
                selected: Number(item.id) === Number(productId),
                label: `${item.cnpj} | ${item.originalName}`,
                value: item.id,
              })
            );
            setAllSelles(optionSeller);
            setLoading(false);
            setResMessage("");
          } catch (error: any) {
            setLoading(true);
            setResMessage(`Erro ao consultar os anunciantes ${error.message}`);
            setResType("warning");
          }
        };
        await loadOptionsSellers();
      } catch (error: any) {
        setLoading(true);
        setResMessage(`Erro ao consultar o cupom ${error.message}`);
        setResType("warning");
      }
    };
    loadProduct();
    // const loadOptionsSelles = async () => {
    //   try {
    //     const { error, data } = await API.list(endpoints.SELLERS);
    //     if (error || data.length < 1) {
    //       throw new Error(error);
    //     }
    //     const optionSeller: AllOptionSellers = data.map((item: ItemSeller) => ({
    //       label: `${item.cnpj} | ${item.originalName}`,
    //       value: item.id,
    //     }));
    //     setAllSelles(optionSeller);
    //   } catch (error: any) {
    //     setResMessage(`Erro ao consultar anunciantes ${error.message}`);
    //     setResType("warning");
    //   }
    // };
    // loadOptionsSelles();
    return () => {
      setProduct({ ...initialProducts });
      setProductError({ ...initialProducts });
    };
  }, []);

  const handleChange = (
    evt: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { value, id, name } = evt.target;
    const inputName = id || name;
    setProduct((state) => ({ ...state, [inputName]: value }));
    setProductError((state) => ({ ...state, [inputName]: null }));
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await schema.validate(product, { abortEarly: false });
      try {
        let error, data;
        if (method === METHODS.create) {
          ({ error, data } = await API.create(endpoints.PRODUCTS, product));
        } else if (method === METHODS.update) {
          ({ error, data } = await API.update(endpoints.PRODUCTS, product, {
            id: productId,
          }));
        }
        if (error) {
          throw new Error(error);
        }
        setResMessage(method === 'CREATE' ? 'Registrado com sucesso!' : 'Atualizado com sucesso!');
        setResType("success");
        setProduct({ ...initialProducts });
        setProductError({ ...initialProducts });
        setLoading(false);
      } catch (error: any) {
        setResMessage(error.message);
        setResType("warning");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error instanceof Yup.ValidationError) {

        error.inner.forEach(({ path, message }) => {
          setProductError((state) => ({ ...state, [path as string]: message }));
        });
      }
    }
  };

  return (
    <>
    {/* <VitrineShoppable></VitrineShoppable> */}
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => navigate("/products")}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {method === METHODS.create ? 'Registrar Produto' : 'Atualizar Produto'}  
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 2, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        {Boolean(resMessage) && (
          <Alert
            severity={resType === "success" ? "success" : "warning"}
            onClose={() => setResMessage("")}
          >
            {resMessage}
          </Alert>
        )}
        <TextField
          required
          onChange={handleChange}
          error={Boolean(productError.code)}
          id="code"
          label="Codigo:"
          helperText={productError.code}
          variant="standard"
          value={product.code}
        />
        <TextField
          required
          onChange={handleChange}
          error={Boolean(productError.name)}
          id="name"
          label="Nome do Produto: "
          helperText={productError.name}
          variant="standard"
          value={product.name}
        />
        <TextField
          required
          onChange={handleChange}
          error={Boolean(productError.link)}
          id="link"
          label="Link do produto: "
          helperText={productError.link}
          variant="standard"
          value={product.link}
        />
        <TextField
          required
          onChange={handleChange}
          error={Boolean(productError.shortUrl)}
          id="shortUrl"
          label="Link encurtado do produto: "
          helperText={productError.shortUrl}
          variant="standard"
          value={product.shortUrl}
        />

        <TextField
          required
          onChange={handleChange}
          error={Boolean(productError.image)}
          id="image"
          label="Link da Imagem do Produto:"
          helperText={productError.image}
          variant="standard"
          value={product.image}
        />
        <TextField
          onChange={handleChange}
          error={Boolean(productError.imageSecondary)}
          id="imageSecondary"
          label="Link da Imagem secundaria do Produto:"
          helperText={productError.imageSecondary}
          variant="standard"
          value={product.imageSecondary}
        />
        <TextField
          onChange={handleChange}
          error={Boolean(productError.video)}
          id="video"
          label="Link do video do Produto:"
          helperText={productError.video}
          variant="standard"
          value={product.video}
        />
        <TextField
          
          onChange={handleChange}
          error={Boolean(productError.originalPrice)}
          id="originalPrice"
          InputProps={{ inputProps: { min: 36, max: 40 } }}
          label="Valor Original do Produto:"
          helperText={productError.originalPrice}
          variant="standard"
          value={product.originalPrice}
          type="number"
        />
        <TextField
          required
          onChange={handleChange}
          error={Boolean(productError.actualPrice)}
          id="actualPrice"
          label="Valor com desconto do Produto:"
          helperText={productError.actualPrice}
          variant="standard"
          value={product.actualPrice }
          type="number"
        />
        <TextField
          onChange={handleChange}
          error={Boolean(productError.installmentPrice)}
          id="installmentPrice"
          label="Valor das parcelas:"
          helperText={productError.installmentPrice}
          variant="standard"
          value={product.installmentPrice}
          type="number"
        />
        <TextField
          
          onChange={handleChange}
          error={Boolean(productError.installmentNro)}
          id="installmentNro"
          label="Numero de parcelas:"
          helperText={productError.installmentNro}
          variant="standard"
          value={product.installmentNro}
        />
        <TextField
          
          onChange={handleChange}
          error={Boolean(productError.discount)}
          id="discount"
          label="Desconto:"
          helperText={productError.discount}
          variant="standard"
          value={product.discount}
        />
        <TextField
          onChange={handleChange}
          error={Boolean(productError.sku)}
          id="sku"
          label="sku:"
          helperText={productError.sku}
          variant="standard"
          value={product.sku}
        />

        <TextField
          onChange={handleChange}
          error={Boolean(productError.category)}
          id="category"
          label="Categoria do Produto:"
          helperText={productError.category}
          variant="standard"
          value={product.category}
        />
        <TextField
          onChange={handleChange}
          error={Boolean(productError.quantity)}
          id="quantity"
          label="Quantidade de Produtos:"
          helperText={productError.quantity}
          variant="standard"
          value={product.quantity}
        />
        <TextField
          onChange={handleChange}
          error={Boolean(productError.supplier)}
          id="supplier"
          label="Fornecedor do Produtos:"
          helperText={productError.supplier}
          variant="standard"
          value={product.supplier}
        />
        <TextField
          onChange={handleChange}
          error={Boolean(productError.productNote)}
          id="productNote"
          label="Notas do Produto:"
          helperText={productError.productNote}
          variant="standard"
          value={product.productNote}
        />
        <TextField
          onChange={handleChange}
          error={Boolean(productError.dimension)}
          id="dimension"
          label="Dimensões do produto:"
          helperText={productError.dimension}
          variant="standard"
          value={product.dimension}
        />
        <TextField
          onChange={handleChange}
          error={Boolean(productError.description)}
          multiline
          rows={4}
          maxRows={7}
          id="description"
          label="Descrição do produto: "
          helperText={productError.description}
          variant="standard"
          value={product.description}
        />

        <TextField
          required
          select
          onChange={handleChange}
          error={Boolean(productError.sellerId)}
          id="sellerId"
          name="sellerId"
          label="Anunciante:"
          helperText={productError.sellerId}
          variant="standard"
          value={product.sellerId}
        >
          {allTypes.length > 0 &&
            allSelles.map((option: OptionSeller) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </TextField>
        {!loading ? (
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ m: 2 }}
            fullWidth
            disableElevation
          >
           {method === METHODS.create ? 'Registrar' : 'Atualizar'} 
          </Button>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ m: 2 }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
}

export default CreateProduct;
