import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Backdrop, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import API, { endpoints } from '../../../services/api/API';

interface product {
    id: number;
    code: string;
    name: string;
    title: string;
    type: string;
}

function ListProduct() {
    const [products, setProducts] = useState<product[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [productToDelete, setProductToDelete] = useState<product | null>(null);

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        setLoading(true);
        const { error, data } = await API.list(endpoints.PRODUCTS);
        if (!error) {
          setProducts([...data]);
        }
        setLoading(false);
    };

    const deleteProduct = async (productId: number) => {
        setLoading(true);
        const { error, data } = await API.remove(endpoints.PRODUCTS, productId);
        if (!error) {
            fetchProducts();
        }
        setLoading(false);
    };

    const handleDeleteClick = (coupon: product) => {
        setProductToDelete(coupon);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (productToDelete) {
            await deleteProduct(productToDelete.id);
            setProductToDelete(null);
            setDeleteDialogOpen(false);
        }
    };

    const handleDeleteCancel = () => {
        setProductToDelete(null);
        setDeleteDialogOpen(false);
    };

    const renderProducts = () => products.map((product) => (
        <ListItem key={product.id}>
            <ListItemText
                primary={product.title}
                secondary={`Código: ${product.code}, Nome: ${product.name}`}
            />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit">
                    <Link to={`/products/${product.id}/edit`}>
                        <EditIcon />
                    </Link>
                </IconButton>
               
            </ListItemSecondaryAction>
        </ListItem> 
    ))  

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <h1>Produtos</h1>
                <Button variant="outlined" color="primary" component={Link} to="/products/create">
                    Cadastrar Novo
                </Button>
            </Box>
            <List>
                {renderProducts()}
            </List>

            <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
                <DialogTitle>{"Excluir Produto"}</DialogTitle>
                <DialogContent>
                    <p>Você realmente deseja excluir este cupom?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>

            <Backdrop open={loading} style={{ zIndex: 1000 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default ListProduct;
