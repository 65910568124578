import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker} from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {Moment} from "moment-timezone";

type PropsFieldData={
    label:string
    defaultValue:any;
    value:any;
    minDate:any;
    name:string;
    onChange:(value:Moment, target:any, name:string)=>void;   
}

export default function FieldData({label, defaultValue, value, onChange, minDate , name}:PropsFieldData) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker 
            label={label} 
            views={['day','month','year']} 
            defaultValue={ defaultValue } 
            minDate={minDate} 
            value={value} 
            onChange={(value, target)=>onChange(value, target, name)} 
            format={"DD-MM-YYYY"} 
            name={name}/>
      </DemoContainer>
    </LocalizationProvider>
  );
}