import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Backdrop, Box, Avatar } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import API, { endpoints } from '../../../services/api/API';

interface Seller {
    id: number;
    fantasyName: string;
    email: string;
    cnpj: string;
    sellerIcon: string;
}

function ListSeller() {
    const [sellers, setSellers] = useState<Seller[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [sellerToDelete, setSellerToDelete] = useState<Seller | null>(null);

    useEffect(() => {
        fetchSellers();
    }, []);

    const fetchSellers = async () => {
        setLoading(true);
        const { error, data } = await API.list(endpoints.SELLERS);
        if (!error) {
            setSellers([...data]);
        }
        setLoading(false);
    };

    const deleteSeller = async (sellerId: number) => {
        setLoading(true);
        const { error, data } = await API.remove(endpoints.SELLERS, sellerId);
        if (!error) {
            fetchSellers();
        }
        setLoading(false);
    };

    const handleDeleteClick = (seller: Seller) => {
        setSellerToDelete(seller);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (sellerToDelete) {
            await deleteSeller(sellerToDelete.id);
            setSellerToDelete(null);
            setDeleteDialogOpen(false);
        }
    };

    const handleDeleteCancel = () => {
        setSellerToDelete(null);
        setDeleteDialogOpen(false);
    };

    const renderSellers = () => sellers.map((seller) => (
        <ListItem key={seller.id}>
            <ListItemAvatar>
                <Avatar variant='square' src={seller.sellerIcon} />
            </ListItemAvatar>
            <ListItemText
                primary={seller.fantasyName}
                secondary={`Email: ${seller.email}, CNPJ: ${seller.cnpj}`}
            />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit">
                    <Link to={`/sellers/${seller.id}/edit`}>
                        <EditIcon />
                    </Link>
                </IconButton>
              
            </ListItemSecondaryAction>
        </ListItem>
    ));

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <h1>Anunciantes</h1>
                <Button variant="outlined" color="primary" component={Link} to="/sellers/create">
                    Cadastrar Novo
                </Button>
            </Box>
            <List>
                {renderSellers()}
            </List>

            <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
                <DialogTitle>{"Excluir Anunciante"}</DialogTitle>
                <DialogContent>
                    <p>Você realmente deseja excluir este anunciante?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>

            <Backdrop open={loading} style={{ zIndex: 1000 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default ListSeller;
