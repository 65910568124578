import React from 'react';
import {
    Navigate,
    createBrowserRouter
  } from "react-router-dom";
import ErrorPage from "./components/views/error-page";
import UnauthorizedPage from "./components/views/unauthorized";

import ResponsiveDrawer from "./components/ResponsiveDrawer";

import Listcoupon from './components/views/coupons/ListCoupons';
import ListSeller from './components/views/sellers/ListSeller';
import CreateSeller from "./components/views/sellers/CreateSeller";
import CreateCoupon from './components/views/coupons/CreateCoupon';
import UpdateSeller from "./components/views/sellers/UpdateSeller";
import UpdateCoupon from "./components/views/coupons/UpdateCoupon";
import {Listcampaign,CreateUpdateCampaign} from './components/views/campaigns';
import ListProduct from './components/views/products/ListProduct';
import CreateProduct from './components/views/products/CreateProduct';
import UpdateProduct from './components/views/products/UpdateProduct';
import { METHODS } from './enums/methods';

export const router = createBrowserRouter([
    {
        path: "/",
        element: <ResponsiveDrawer />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <ListSeller />,
            },
            {
                path: "/sellers/create",
                element: <CreateSeller method={METHODS.create} />,
            },
            {
                path: "/sellers",
                element: <ListSeller />,
            },
            {
                path: "/coupons/create",
                element: <CreateCoupon method={METHODS.create} />,
            },
            {
                path: "/coupons",
                element: <Listcoupon />,
            },
            {
                path: "/unauthorized",
                element: <UnauthorizedPage />
            },
            {
                path: "/coupons/:couponId/edit",
                element: <CreateCoupon method={METHODS.update} />
            },
            {
                path: "/sellers/:sellerId/edit",
                element: <CreateSeller method={METHODS.update}/>
            },
            {
                path: "/campaigns",
                element: <Listcampaign />,
            },
            {
                path: "/campaigns/create",
                element: <CreateUpdateCampaign />,
            },
            {
                path: "/campaigns/:campaignId/edit",
                element: <CreateUpdateCampaign />,
            },
            {
                path: "/products",
                element: <ListProduct />,
            },
            {
                path: "/products/create",
                element: <CreateProduct  method={METHODS.create}/>,
            },
            {
                path: "/products/:productId/edit",
                element: <CreateProduct  method={METHODS.update}/>,
            },
        ],
    }
]);