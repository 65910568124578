import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CreateCoupon from '../coupons/CreateCoupon';
import {METHODS} from "../../../enums/methods"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type PropsModalCampaign={
  action:string;
  flag:string;
  openModal:boolean;
  closeModal:any;
  addItem?:(item:any)=>void;
  currentItem?:any, 
  updateItem?:(item:any)=>void;
}

export default function ModalCampaign({action, flag, openModal, addItem, closeModal, currentItem, updateItem}:PropsModalCampaign) {
  const ActionComponent = ()=>{
    switch (action) {
      case METHODS.create:
        return <>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Cadastrar cupons
          </Typography>
          <CreateCoupon method={METHODS.create} addItem={addItem} />
        </>
      default:
        return <>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Atualizar cupons
        </Typography>
        <CreateCoupon method={METHODS.update} updateItem={updateItem} currentItem={currentItem} />
      </>
    }
  }
  return (
    <div>
      <Modal
        open={openModal}
        onClose={()=>closeModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ActionComponent />
        </Box>
      </Modal>
    </div>
  );
}