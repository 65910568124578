import React, { useState, useEffect } from "react";
import API, { endpoints } from "../../../services/api/API";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  Box,
  Button,
  TextField,
  Alert,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {METHODS} from '../../../enums/methods'

const initialSellers = {
  originalName: "",
  fantasyName: "",
  companyName: "",
  address: "",
  sellerIcon: "",
  email: "",
  phone: "",
  cnpj: "",
  advertiserUrl: "",
  note: null,
};

function CreateSeller({ method }: any) {
  const schema = Yup.object().shape({
    cnpj: Yup.string()
      .matches(/^\d{14}$/, "Insira um cnpj válido Ex: 012345678901")
      .required("O cnpj não é válido"),
    email: Yup.string().email("O email não é válido"),
    originalName: Yup.string().required("O nome é inválido"),
    sellerIcon: Yup.string()
      .url("URL não  é inválido")
      .required("URL não  é inválido"),
    advertiserUrl: Yup.string()
      .url("URL não  é inválido")
      .required("URL não  é inválido"),
    fantasyName: Yup.string().required("Campo obrigatório"),
    companyName: Yup.string().required("Campo obrigatório"),
    address: Yup.string().nullable(),
    phone: Yup.string(),
    note: Yup.string().nullable().max(200),
  });

  const [seller, setSeller] = useState({ ...initialSellers });
  const [sellerError, setSellerError] = useState({ ...initialSellers });
  const [resMessage, setResMessage] = useState<string>("");
  const [resType, setResType] = useState("warning");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { sellerId } = useParams();

  useEffect(() => {
    setSeller({ ...initialSellers });
    setSellerError({ ...initialSellers });

    if (method === METHODS.update) {
      const loadSeller = async () => {
        try {
          const { error, data } = await API.one(endpoints.SELLERS, {
            id: sellerId,
          });
          if (error || data.length < 1) {
            throw new Error(error);
          }
          const {
            originalName,
            fantasyName,
            companyName,
            address,
            sellerIcon,
            email,
            phone,
            cnpj,
            advertiserUrl,
            note,
          } = data;
          setSeller({
            originalName,
            fantasyName,
            companyName,
            address,
            sellerIcon,
            email,
            phone,
            cnpj,
            advertiserUrl,
            note,
          });
          setLoading(false);
          setResMessage("");
        } catch (error: any) {
          setLoading(true);
          setResMessage(`Erro ao consultar anunciantes ${error.message}`);
          setResType("warning");
        }
      };

      loadSeller();
    }

    return () => {
      setSeller({ ...initialSellers });
      setSellerError({ ...initialSellers });
    };
  }, []);

  const handleChange = (
    evt: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { value, id } = evt.target;
    setSeller((state) => ({ ...state, [id]: value }));
    setSellerError((state) => ({ ...state, [id]: null }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await schema.validate(seller, { abortEarly: false });
      try {
        let error, data;
        if (method === METHODS.create) {
          ({ error, data } = await API.create(endpoints.SELLERS, seller));
        } else if (method === METHODS.update) {
          ({ error, data } = await API.update(endpoints.SELLERS, seller, {
            id: sellerId,
          }));
        }

        if (error) {
          throw new Error(error);
        }
        setResMessage(
          method === "CREATE"
            ? "Registrado com sucesso!"
            : "Atualizado com sucesso!"
        );
        setResType("success");
        setSeller({ ...initialSellers });
        setSellerError({ ...initialSellers });
        setLoading(false);
      } catch (error: any) {
        setResMessage(error.message);
        setResType("warning");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(({ path, message }) => {
          setSellerError((state) => ({ ...state, [path as string]: message }));
        });
      }
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => navigate("/sellers")}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {method === METHODS.create
                ? "Registrar Anunciante"
                : "Atualizar Anunciante"}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 2, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        {Boolean(resMessage) && (
          <Alert
            severity={resType === "success" ? "success" : "warning"}
            onClose={() => setResMessage("")}
          >
            {resMessage}
          </Alert>
        )}
        <TextField
          required
          onChange={handleChange}
          error={Boolean(sellerError.cnpj)}
          id="cnpj"
          label="CNPJ:"
          helperText={sellerError.cnpj}
          variant="standard"
          value={seller.cnpj}
        />
        <TextField
          required
          onChange={handleChange}
          error={Boolean(sellerError.originalName)}
          id="originalName"
          label="Nome do anunciante:"
          helperText={sellerError.originalName}
          variant="standard"
          value={seller.originalName}
        />
        <TextField
          required
          onChange={handleChange}
          error={Boolean(sellerError.fantasyName)}
          id="fantasyName"
          label="Nome fantasia:"
          helperText={sellerError.fantasyName}
          variant="standard"
          value={seller.fantasyName}
        />
        <TextField
          required
          onChange={handleChange}
          error={Boolean(sellerError.companyName)}
          id="companyName"
          label="Nome da companhia:"
          helperText={sellerError.companyName}
          variant="standard"
          value={seller.companyName}
        />
        <TextField
          onChange={handleChange}
          error={Boolean(sellerError.address)}
          id="address"
          label="Endereço:"
          helperText={sellerError.address}
          variant="standard"
          value={seller.address}
        />
        <TextField
          required
          onChange={handleChange}
          error={Boolean(sellerError.sellerIcon)}
          id="sellerIcon"
          label="Icon:"
          helperText={sellerError.sellerIcon}
          variant="standard"
          value={seller.sellerIcon}
        />
        <TextField
          required
          onChange={handleChange}
          error={Boolean(sellerError.advertiserUrl)}
          id="advertiserUrl"
          label="URL site do anunciante:"
          helperText={sellerError.advertiserUrl}
          variant="standard"
          value={seller.advertiserUrl}
        />
        <TextField
          onChange={handleChange}
          error={Boolean(sellerError.email)}
          id="email"
          label="Email:"
          helperText={sellerError.email}
          variant="standard"
          value={seller.email}
        />
        <TextField
          onChange={handleChange}
          error={Boolean(sellerError.phone)}
          id="phone"
          label="Telefone:"
          helperText={sellerError.phone}
          variant="standard"
          value={seller.phone}
        />
        <TextField
          onChange={handleChange}
          error={Boolean(sellerError.note)}
          id="note"
          label="Observação:"
          helperText={sellerError.note}
          variant="standard"
          value={seller.note}
        />
        {!loading ? (
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ m: 2 }}
            fullWidth
            disableElevation
          >
            {method === METHODS.create ? "Registrar" : "Atualizar"}
          </Button>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ m: 2 }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
}

export default CreateSeller;
