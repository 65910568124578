import React, { useState, useEffect, useRef} from "react";
import API, { endpoints } from "../../../services/api/API";
import { useNavigate, useParams } from "react-router-dom";
import FieldData from "./FieldData";
import SearchListItems from "./SearchListItems";
import moment, { Moment, now } from "moment-timezone";
import * as Yup from "yup";
import ModalCampaign from "./ModalCampaign";
import { METHODS } from "../../../enums/methods";
import {
  Box,
  Button,
  TextField,
  Alert,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CircularProgress,
  Grid,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { flags } from "../../../services/flags";
import ModalProducts from "../modalProducts";
import Modal from "@mui/material/Modal";
import ExternalModuleComponent from "../wc/vitrineShoppable";
import CuponsComponent from "../wc/vitrineCupons";
// import VitrineShoppable from "https://vault.globoi.com/p/shoppable-vault/storage/preview/wc-vitrine/vitrine-latest-preview.js"
// const { test } = await import(/* webpackIgnore: true */ "https://vault.globoi.com/p/shoppable-vault/storage/preview/wc-vitrine/vitrine-latest-preview.js");

const initialCampaign = {
  code: "",
  name: "",
  address: "",
  sellerId: null,
  status: 1,
  dateStart: moment.tz("America/Sao_Paulo"),
  dateEnd: moment.tz("America/Sao_Paulo").add(30, "day"),
};

type OptionSeller = {
  label: string;
  value: string;
};

type AllOptionSellers = OptionSeller[];

type ItemType = {
  id: string | number;
  code: string;
  title?: string;
  name?: string;
  link: string;
  sellerId?:string|number;
};

type ItemSeller = {
  id: string | number;
  cnpj: String;
  originalName: String | null;
};

type ItemTypes = ItemType[];

function CreateUpdateCampaign() {
  const defaultSchema = {
    code: Yup.string().required("Codigo é obrigatório"),
    name: Yup.string().required("Titulo é obrigatório"),
    address: Yup.string().nullable(),
    dateStart: Yup.string().nullable(),
    dateEnd: Yup.string().nullable(),
  };

  const [campaign, setCampaign] = useState({ ...initialCampaign });
  const [campaignError, setCampaignError] = useState({ ...initialCampaign });
  const [resMessage, setResMessage] = useState<string>("");
  const [resType, setResType] = useState("warning");

  const [itemList, setItemsList] = useState<ItemTypes>([]);
  const [currentItem, setCurrentItem] = useState<any>([]);

  const [selectedValueRadio, setSelectedValueRadio] = React.useState(
    flags.COUPONS
  );
  const [openModalCampaign, setOpenModalCampaign] = useState(false);
  const { campaignId } = useParams();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [allSelles, setAllSelles] = useState<AllOptionSellers>([]);
  const [actionModalCampaign, setActionModalCampaign] = useState(METHODS.create);
  const [indexItemUpdate, setIndexItemUpdate] = useState(-1);
  const [RemoteComponent, setRemoteComponent] = useState(null);
  const [campaignFormattedPreview, setCampaignFormattedPreview] = useState({})
  const [allTypes, setAllTypes] = useState([
    {
      value: "voucher",
      label: "Voucher",
    },
  ]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const nextButtonRef = useRef(null);
  useEffect(() => {
    const teste = transformObject(campaign);
    setCampaignFormattedPreview(teste)
  },[itemList])
      useEffect(() => {
      

      const nextButton = nextButtonRef.current;
      if (nextButton) {
        //@ts-ignore
          nextButton.addEventListener('click', handleBtnNext);
      }

    
    setCampaign({ ...initialCampaign });
    setCampaignError({ ...initialCampaign });
    setItemsList([]);
    setResMessage("");

    if (campaignId) {
      loadCampaign();
    } else {
      if (selectedValueRadio === flags.PRODUCTS) {
        loadOptionsSellers();
      }
    }
    return () => {
      setCampaign({ ...initialCampaign });
      setCampaignError({ ...initialCampaign });
      setItemsList([]);
      setResMessage("");
    };
  }, [selectedValueRadio, campaignId]);

  const loadCampaign = async () => {

    try {
      const { error, data } = await API.one(endpoints.CAMPAIGN, {
        id: campaignId,
      });

      if (error || data.length < 1) {
        throw new Error(error);
      }

      const { code, name, flag, address, status, seller, dateStart, dateEnd } = data;

      setSelectedValueRadio(flag);

      const resInitialCampaign = {
        code,
        name,
        flag,
        address,
        sellerId: seller ? seller.id : null,
        [flags.COUPONS === flag ? "cuponsId" : "productsId"]: [],
        status,
        dateStart: moment.tz(dateStart, "America/Sao_Paulo"),
        dateEnd: moment.tz(dateEnd, "America/Sao_Paulo"),
      };

      const { campaignsCoupons, campaignsProducts } = data;

      const items =
        flag === flags.COUPONS ? campaignsCoupons : campaignsProducts;

      if (items.length > 0) {
        const allItems = items.map((item: any) =>{
          if (flag === flags.COUPONS) {
            const {
              id,
              code,
              title,
              description,
              terms,
              discount,
              link,
              type,
            } = item.coupon;
            const {id:sellerId} = item.coupon.seller;
            return {
              id,
              code,
              title,
              description,
              terms,
              discount,
              link,
              sellerId,
              type
            }
          }
          return item.product;
        });
        const initialCampaign = {
          ...resInitialCampaign,
          [flags.COUPONS === flag ? "cuponsId" : "productsId"]: allItems,
        };
        setItemsList(allItems);
        setCampaign({ ...initialCampaign });
      } else {
        setCampaign({ ...resInitialCampaign });
      }
      if (seller) {
        loadOptionsSellers(seller.id);
      }
    } catch (error: any) {
      setLoading(true);
      setResMessage(`Erro ao consultar a campanha ${error.message}`);
      setResType("warning");
    }
  };

  const handleChange = (
    evt: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { value, id, name } = evt.target;
    const inputName = id || name;
    setCampaign((state) => ({ ...state, [inputName]: value }));
    setCampaignError((state) => ({ ...state, [inputName]: null }));
  };

  const handleChangeData = (value: Moment, target: any, name: string) => {
    const { validationError } = target;
    if (validationError) {
      setCampaign((state) => ({ ...state, [name]: null }));
    } else {
      setCampaign((state) => ({ ...state, [name]: value }));
    }
  };


  const registeringCoupons = async ()=>{
    let newItemsList:ItemTypes=[...itemList];
    try {
      let i=-1;
      for (const item of itemList) {
        i++;
        const {id: idItem} = item;
        if (idItem) {
          const {data:dataRes} = await API.one(endpoints.COUPONS, {id:idItem})
          const {id} = dataRes;
          if (id) {
            const {sellerId} = item;
            const {error:errorResUp, data:dataResUp}  = await API.update(endpoints.COUPONS, {...item, seller:sellerId}, {id:id});
            const {id:idUpdate} = dataResUp;
            if (!idUpdate || errorResUp) {
              throw new Error(`Erro ao atualizar o cupom ${item?.title} "${errorResUp}"`);
            }
            newItemsList[i]=item;
            continue;       
          }
        }
        const { error:errorResReg, data:dataResReg } = await API.create(endpoints.COUPONS, item);
        const { id } = dataResReg;
        if (!id || errorResReg) {
          throw new Error(`Erro ao cadastrar o cupom ${item?.title} "${errorResReg}"`);
        } else {
          newItemsList[i]=dataResReg;
        } 
      }  
      setItemsList(newItemsList);
      return newItemsList;
    } catch (error:any) {
      setItemsList(newItemsList);
      throw new Error(error.message);
    }
  }

  const handleSubmit = async () => {
    console.log("submitt")
    setLoading(true);
    try {
      const validationSchema =
        selectedValueRadio === flags.COUPONS
          ? defaultSchema
          : {
              ...defaultSchema,
              sellerId: Yup.string().required("Anunciante é obrigatório"),
            };
      const schema = Yup.object().shape(validationSchema);
      await schema.validate(campaign, { abortEarly: false });
   
      try {
        const { dateStart, dateEnd } = campaign;
        //@ts-ignore
        let itemsId 
        let newItemsList
        if(selectedValueRadio ===flags.COUPONS){
           newItemsList =  await registeringCoupons(); 
           itemsId = newItemsList.reduce((values:any, {id})=>{
            if (id) {
              values.push(id)
            }
            return values;
          },[]);
        }
        else {
          itemsId = itemList.reduce((values:any, {id})=>{
            if (id) {
              values.push(id)
            }
            return values;
          },[]);
        }
    
        const newdata = {
          ...campaign,
          flag: selectedValueRadio,
          dateStart: dateStart
            .tz("America/Sao_Paulo")
            .format("YYYY-MM-DD HH:mm:ss"),
          dateEnd: dateEnd
            .tz("America/Sao_Paulo")
            .format("YYYY-MM-DD HH:mm:ss"),
          [selectedValueRadio === flags.COUPONS ? "cuponsId" : "productsId"]:itemsId,
        };
        if (campaignId) {
          const { error, data } = await API.update(endpoints.CAMPAIGN,newdata,{ id: campaignId });
          if (error) {
            throw new Error(error);
          }
          setResMessage("Atualizado com sucesso!");
        } else {
          const { error, data } = await API.create(endpoints.CAMPAIGN, newdata);
          if (error) {
            throw new Error(error);
          }
          setResMessage("Registrado com sucesso!");
          setItemsList([]);
          setCampaign({ ...initialCampaign });
          setCampaignError({ ...initialCampaign });
        }
        setResType("success");
        setLoading(false);
      } catch (error: any) {
        setResMessage(error.message);
        setResType("warning");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error instanceof Yup.ValidationError) {
      console.log('yup error schema')

        error.inner.forEach(({ path, message }) => {
          setCampaignError((state) => ({
            ...state,
            [path as string]: message,
          }));
        });
      }
    }
  };

  const addItem = (newItem: any) => {
    setItemsList([...itemList, newItem]);
  };
  const updateItem = (newItemData: any) => {
    // Find the index of the item to update
    const index = selectedValueRadio===flags.COUPONS?indexItemUpdate:itemList.findIndex((item) => item.id === newItemData.id);
    if (index !== -1) {
      const updatedList = [...itemList];
      updatedList[index] = { ...updatedList[index], ...newItemData }; // Merge existing item with new data
      setItemsList(updatedList);
      setCurrentItem(newItemData)
    }
  };

  const onEdit = (indexItem: any, index:number) => {
    setCurrentItem(indexItem);
    if (selectedValueRadio===flags.COUPONS) {
      setActionModalCampaign(METHODS.update);
      setOpenModalCampaign(true);
      setIndexItemUpdate(index);  
    }else{
      setOpen(true);
    }
  };
  const removeItem = (indexItem: string | number) => {
    const newItemsList = itemList.filter((_, index) => index !== indexItem);
    setItemsList(newItemsList);
  };

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValueRadio((event.target as HTMLInputElement).value);
    setItemsList([]);
  };

  const loadOptionsSellers = async (sellerId = null) => {
    try {
      const { error, data } = await API.list(endpoints.SELLERS);
      if (error || data.length < 1) {
        throw new Error(error);
      }
      const optionSeller: AllOptionSellers = data.map((item: ItemSeller) => ({
        selected: sellerId ? Number(item.id) === Number(sellerId) : false,
        label: `${item.cnpj} | ${item.originalName}`,
        value: item.id,
      }));
      setAllSelles(optionSeller);
      setLoading(false);
      setResMessage("");
    } catch (error: any) {
      setLoading(true);
      setResMessage(`Erro ao consultar os anunciantes ${error.message}`);
      setResType("warning");
    }
  };
  const handleOpenCreateProduct = () => {
    setOpen(true);
    setCurrentItem(null);
  };
  const transformObject = (source:any) => {
    return {
      id: 2,
      code: source.code,
      name: source.name,
      flag: source.flag,
      address: source.address,
      active: true, 
      status: source.status,
      dateStart: source.dateStart, 
      dateEnd: source.dateEnd, 
      createdAt: "2024-05-29T20:03:53.518Z",
      updatedAt: "2024-05-29T20:03:53.518Z",
      deletedAt: null, 
      campaignsCoupons: itemList.map((cupom:any) => ({
        id: cupom.id,
        order: cupom.order,
        coupon:{
          id:cupom.id,
          code:cupom.code,
          title:cupom.title,
          description:cupom.description,
          terms:cupom.terms,
          discount:cupom.discount,
          type:cupom.type,
          link:cupom.link,
          active:cupom.status,
          seller: cupom.seller
        }
      })), 
      campaignsProducts: itemList.map((product:any) => ({
        id: product.id,
        order: product.id, 
        createdAt: product.createdAt,
        updatedAt: product.updatedAt,
        deletedAt: product.deletedAt,
        product: {
          id: product.id,
          code: product.code,
          name: product.name,
          link: product.link,
          shortUrl: product.shortUrl,
          description: product.description,
          image: product.image,
          imageSecondary: product.imageSecondary,
          video: product.video,
          originalPrice: product.originalPrice,
          actualPrice: product.actualPrice,
          installmentPrice: product.installmentPrice,
          installmentNro: product.installmentNro,
          discount: product.discount,
          sku: product.sku,
          active: product.active,
          category: product.category,
          quantity: product.quantity,
          supplier: product.supplier,
          productNote: product.productNote,
          dimension: product.dimension,
          createdAt: product.createdAt,
          updatedAt: product.updatedAt,
          deletedAt: product.deletedAt,
          seller: product.seller
        }
      })),
      seller: source?.productsId?.length > 0 ? source?.productsId[0]?.seller : null 
    };
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => navigate("/campaigns")}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {campaignId ? "Atualizar" : "Registrar"} campanha de{" "}
              {selectedValueRadio === flags.COUPONS ? "cupons" : "produtos"}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <br />
      <Box
        sx={{
          "& .MuiTextField-root": { m: 2, width: "100%" },
        }}
      >
        <RadioGroup value={selectedValueRadio} onChange={handleChangeRadio}>
          <Grid container spacing={3}>
            <Grid item xs={6} style={{ paddingLeft: "40px" }}>
              <FormControlLabel
                value={flags.COUPONS}
                control={<Radio />}
                label="Cupons"
                disabled={Boolean(campaignId)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value={flags.PRODUCTS}
                control={<Radio />}
                label="Produtos"
                disabled={Boolean(campaignId)}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </Box>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 2, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        {Boolean(resMessage) && (
          <Alert
            severity={resType === "success" ? "success" : "warning"}
            onClose={() => setResMessage("")}
          >
            {resMessage}
          </Alert>
        )}
        <TextField
          required
          onChange={handleChange}
          error={Boolean(campaignError.code)}
          id="code"
          label="Codigo da campanha:"
          helperText={campaignError.code}
          variant="standard"
          value={campaign.code}
        />
        <TextField
          required
          onChange={handleChange}
          error={Boolean(campaignError.name)}
          id="name"
          label="Nome da campanha:"
          helperText={campaignError.name}
          variant="standard"
          value={campaign.name}
        />
        <TextField
          onChange={handleChange}
          error={Boolean(campaignError.address)}
          id="address"
          label="Endereço:"
          helperText={campaignError.address}
          variant="standard"
          value={campaign.address}
        />

        {selectedValueRadio === flags.PRODUCTS && (
          <TextField
            disabled={Boolean(campaignId)}
            required
            select
            onChange={handleChange}
            error={Boolean(campaignError.sellerId)}
            id="sellerId"
            name="sellerId"
            label="Anunciante:"
            helperText={campaignError.sellerId}
            variant="standard"
            value={campaign.sellerId}
          >
            {allTypes.length > 0 &&
              allSelles.map((option: OptionSeller) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        )}

        <Grid container spacing={2} columns={16}>
          <Grid item xs={8}>
            <FieldData
              label={"Início da campanha *"}
              defaultValue={initialCampaign.dateStart}
              value={campaign.dateStart}
              onChange={handleChangeData}
              name={"dateStart"}
              minDate={initialCampaign.dateStart}
            />
          </Grid>
          <Grid item xs={8}>
            <FieldData
              label={"Fim da campanha *"}
              defaultValue={initialCampaign.dateEnd}
              value={campaign.dateEnd}
              onChange={handleChangeData}
              name={"dateEnd"}
              minDate={initialCampaign.dateStart}
            />
          </Grid>
        </Grid>

        <Button
          variant="contained"
          sx={{ m: 2 }}
          onClick={() =>
            selectedValueRadio === flags.COUPONS
              ? [setActionModalCampaign(METHODS.create),setOpenModalCampaign(true)]
              : handleOpenCreateProduct()
          }
          fullWidth
        >
          Adicionar {selectedValueRadio === flags.COUPONS?"cupom":"produto"}
        </Button>
        <ModalProducts
          open={open}
          closeModal={handleClose}
          addItem={addItem}
          currentItem={currentItem}
          updateItem={updateItem}
        />
        {/* <OpenModalButton openModal={openModal} />
        <ModalProducts isOpen={modalIsOpen} onRequestClose={closeModal} /> */}
        <Divider />
        <ModalCampaign
          action={actionModalCampaign}
          flag={selectedValueRadio}
          addItem={addItem}
          openModal={openModalCampaign}
          closeModal={setOpenModalCampaign}
          currentItem={currentItem}
          updateItem={updateItem}
        />
        <SearchListItems
          itemList={itemList}
          removeItem={removeItem}
          editItem={onEdit}
          flag={selectedValueRadio}
        /> 
      {selectedValueRadio === flags.PRODUCTS ? <ExternalModuleComponent props={campaignFormattedPreview}></ExternalModuleComponent> : 
      //@ts-ignore
      <CuponsComponent props={campaignFormattedPreview}></CuponsComponent>}  
      { 
      //@ts-ignore
      }
        {!loading ? (
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ m: 2 }}
            fullWidth
            disableElevation
          >
            {campaignId ? "Atualizar" : "Registrar"}
          </Button>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ m: 2 }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
}

export default CreateUpdateCampaign;
