import React from 'react';

const UnauthorizedPage: React.FC = () => {

  return (
    <div>
      <h1>Access Denied</h1>
      <p>
        Access not allowed. Please <a href="/auth/login" >login</a>.
      </p>
    </div>
  );
};

export default UnauthorizedPage;
