import React, {useState, useEffect} from "react";
import API,{endpoints} from "../../../services/api/API"
import { useNavigate,useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { 
    Box,
    Button,
    TextField,
    MenuItem,
    Alert,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    CircularProgress
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {METHODS} from '../../../enums/methods'
const initialCoupons = {
    code: "",
    title: "",
    description:"",
    terms:"",
    discount:"",
    link: "",
    type: "voucher",
    sellerId: ""
}

type ItemSeller = {
    id:string|number;
    cnpj:String;
    originalName:String|null;
}

type OptionSeller = {
    label:string;
    value:string;
}

type AllOptionSellers = OptionSeller[];

type PropsCreateCoupon={
    method:string
    addItem?:(item:any)=>void
    currentItem?:any, 
    updateItem?:(item:any)=>void;
}


function CreateCoupon({method,addItem,currentItem, updateItem}:PropsCreateCoupon){
    const schema = Yup.object().shape({
        code: Yup.string().required('Codigo é obrigatório'),
        title: Yup.string().required('Titulo é obrigatório'),
        description:Yup.string(),
        terms:Yup.string(),
        discount:Yup.string(),
        link: Yup.string().url('URL não  é inválido').required('URL não  é inválido'),
        type: Yup.string().required('Otipo e é obrigatório'),
        sellerId: Yup.string().nullable()
    });

    const [coupon, setCoupon] = useState({...initialCoupons});
    const [couponError, setCouponError] = useState({...initialCoupons});
    const [resMessage, setResMessage] = useState<string>("");
    const [resType, setResType] = useState("warning");
    const [allSelles, setAllSelles] = useState<AllOptionSellers>([]);

    const [loading, setLoading] = useState(false);    
    const {couponId} = useParams();

    useEffect(() => {
        setCoupon({...initialCoupons})
        setCouponError({...initialCoupons})
        if (method === METHODS.update) {
            setCoupon({...currentItem});
        }
        const loadCoupon = async () =>{
            try {
                const loadOptionsSellers = async () => {
                    try {
                        const {error, data} = await API.list(endpoints.SELLERS);
                        if(error||data.length<1){
                            throw new Error(error);
                        }      
                        const optionSeller: AllOptionSellers = data.map(
                          (item: ItemSeller) => ({
                            selected:Number(item.id)===Number(couponId),
                            label: `${item.cnpj} | ${item.originalName}`,
                            value: item.id,
                          })
                        );
                        setAllSelles(optionSeller);
                        setLoading(false);
                        setResMessage("");
                    } catch (error:any) {
                        setLoading(true)
                        setResMessage(`Erro ao consultar os anunciantes ${error.message}`);
                        setResType("warning");
                    } 
                }
                await loadOptionsSellers();
            } catch (error:any) {
                setLoading(true)
                setResMessage(`Erro ao consultar o cupom ${error.message}`);
                setResType("warning");
            } 
        }
        loadCoupon();  
      return () => {
        setCoupon({...initialCoupons})
        setCouponError({...initialCoupons})
      }
    },[])

    const handleChange = (evt:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) =>{
        const {value, id, name} = evt.target;
        const inputName=id||name;
        setCoupon((state)=>({...state, [inputName]:value}));
        setCouponError(state=>({...state, [inputName]:null}))
    } 
    
    const handleSubmit = async () =>{
        setLoading(true)
        try {
            await schema.validate(coupon,{ abortEarly: false });
            try {
                if (method === METHODS.create) {
                  if (addItem) {
                    addItem(coupon);
                  }
                  setCoupon({...initialCoupons});
                  setCouponError({...initialCoupons});   
                } else if (method === METHODS.update) {
                    updateItem?.(coupon)                   
                }         
                setResMessage(method === 'CREATE' ? 'Adicionado com sucesso!' : 'Atualizado com sucesso!');
                setResType("success");   
                setLoading(false);          
            } catch (error:any) {
                setResMessage(error.message);
                setResType("warning");
                setLoading(false);
            }   
        } catch (error) {
            setLoading(false);
            if (error instanceof Yup.ValidationError) {
                error.inner.forEach(({path,message})=>{
                    setCouponError(state=>({...state, [path as string]:message}))
                })
            }
        }
    } 
    
    return(
        <>
            <Box
                component="form"
                sx={{
                '& .MuiTextField-root': {width: '100%' , my:1}
                }}
                noValidate
                autoComplete="off"
            >
                {Boolean(resMessage)&&<Alert severity={resType==="success"?"success":"warning"} onClose={()=>setResMessage("")}>
                    {resMessage}
                </Alert>}
                <TextField
                    required
                    onChange={handleChange}
                    error={Boolean(couponError.code)}
                    id="code"
                    label="Codigo:"
                    helperText={couponError.code}
                    variant="standard"
                    value={coupon.code}
                />
                <TextField
                    required
                    onChange={handleChange}
                    error={Boolean(couponError.title)}
                    id="title"
                    label="Titulo do cupom: "
                    helperText={couponError.title}
                    variant="standard"
                    value={coupon.title}
                />
                <TextField
                    onChange={handleChange}
                    error={Boolean(couponError.description)}
                    id="description"
                    label="Descrição do cupom: "
                    helperText={couponError.description}
                    variant="standard"
                    value={coupon.description}
                />
                <TextField
                    onChange={handleChange}
                    error={Boolean(couponError.terms)}
                    id="terms"
                    label="Termos e Condições:"
                    helperText={couponError.terms}
                    variant="standard"
                    value={coupon.terms}
                />
                <TextField
                    onChange={handleChange}
                    error={Boolean(couponError.discount)}
                    id="discount"
                    label="Valor de desconto:"
                    helperText={couponError.discount}
                    variant="standard"
                    value={coupon.discount}
                    type="number"
                />
                <TextField
                    required
                    onChange={handleChange}
                    error={Boolean(couponError.link)}
                    id="link"
                    label="Url do cupom:"
                    helperText={couponError.link}
                    variant="standard"
                    value={coupon.link}
                />
                <TextField
                    required
                    select
                    onChange={handleChange}
                    error={Boolean(couponError.sellerId)}
                    id="sellerId"
                    name="sellerId"
                    label="Anunciante:"
                    helperText={couponError.sellerId} 
                    variant="standard"
                    value={coupon.sellerId}
                    >
                    {allSelles.length>0&&allSelles.map((option:OptionSeller) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                {!loading ? (
                <Button sx={{mt:4}} variant="contained" onClick={handleSubmit} fullWidth disableElevation >
                   {method === METHODS.create ? 'Adicionar' : 'Atualizar'}
                </Button>
                ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ mt: 4 }}
                    >
                      <CircularProgress />
                    </Box>
                )}
            </Box>
        </>
    )
}

export default CreateCoupon;