import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Modal from "@mui/material/Modal";
import API, { endpoints } from "../../../services/api/API";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Alert,
  MenuItem,
} from "@mui/material";
import * as Yup from "yup";
import { v4 as uuidv4 } from 'uuid';
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const initialProducts = {
  code: "",
  name: "",
  link: "",
  shortUrl: "",
  description: "",
  image: "",
  imageSecondary: "",
  video: "",
  originalPrice: "",
  actualPrice: "",
  installmentPrice: "",
  installmentNro: "",
  discount: "",
  sku: "",
  active: 1,
  category: "",
  quantity: "",
  supplier: "",
  productNote: "",
  dimension: "",
  sellerId: "",
};

type ItemSeller = {
  id: string | number;
  cnpj: String;
  originalName: String | null;
};
type OptionSeller = {
  label: string;
  value: string;
};
type AllOptionSellers = OptionSeller[];
function ModalProducts({ open, closeModal,addItem, currentItem, updateItem}: any) {
  const [product, setProduct] = useState({ ...initialProducts });
  const [productError, setProductError] = useState({ ...initialProducts });
  const [loading, setLoading] = useState(false);
  const [resMessage, setResMessage] = useState<string>("");
  const [resType, setResType] = useState("warning");
  const [allSelles, setAllSelles] = useState<AllOptionSellers>([]);
  const [allTypes, setAllTypes] = useState([
    {
      value: "voucher",
      label: "Voucher",
    },
  ]);
  const schema = Yup.object().shape({
    code: Yup.string().nullable(),
    name: Yup.string().required("Titulo é obrigatório"),
    link: Yup.string()
      .url("link não  é inválido")
      .required("URL não  é inválido"),
    shortUrl: Yup.string()
      .url("shortUrl não  é inválido").nullable(),
    description: Yup.string(),
    image: Yup.string()
      .url("URL não  é inválido")
      .required("URL não  é inválido"),
    imageSecondary: Yup.string()
      .url("URL não  é inválido").nullable(),
    video: Yup.string()
      .url("URL não  é inválido").nullable(),
    originalPrice: Yup.string().nullable(),
    actualPrice: Yup.string().required(),
    installmentPrice: Yup.string().nullable(),
    installmentNro: Yup.string().nullable(),
    discount: Yup.string().nullable(),
    sku: Yup.string().nullable(),
    category: Yup.string().nullable(),
    quantity: Yup.string().nullable(),
    supplier: Yup.string().nullable(),
    productNote: Yup.string().nullable(),
    dimension: Yup.string().nullable(),
    sellerId: Yup.string().required("Anunciante é obrigatório"),
  });
  const handleChange = (
    evt: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { value, id, name } = evt.target;
    const inputName = id || name;
    setProduct((state) => ({ ...state, [inputName]: value }));
    setProductError((state) => ({ ...state, [inputName]: null }));
  };

  useEffect(() => {
    setProduct({ ...initialProducts });
    setProductError({ ...initialProducts });
    // if (currentItem && currentItem.seller) {
    //   currentItem.sellerId = currentItem.seller.id;
    //   console.log("Set currentItem.sellerId:", Number(currentItem.sellerId));
    // }
    if(currentItem ){
      const itemWithSellerId = {
        ...currentItem,
        sellerId: currentItem.seller ? currentItem.seller.id : null,
      };
      setProduct(itemWithSellerId);
    }
    
    const loadProduct = async () => {
      try {
      
        const loadOptionsSellers = async () => {
          try {
            // if (currentItem) setProduct({...currentItem})
              
           
            const { error, data } = await API.list(endpoints.SELLERS);
            if (error || data.length < 1) {
              throw new Error(error);
            }
            const optionSeller: AllOptionSellers = data.map(
              (item: ItemSeller) => ({
                selected: Number(item.id) === Number(currentItem?.seller?.id),
                label: `${item.cnpj} | ${item.originalName}`,
                value: item.id,
              })
            );
            setAllSelles(optionSeller);
            setLoading(false);
            setResMessage("");
          } catch (error: any) {
            setLoading(true);
            setResMessage(`Erro ao consultar os anunciantes ${error.message}`);
            setResType("warning");
          }
        };
        await loadOptionsSellers();
      } catch (error: any) {
        setLoading(true);
        setResMessage(`Erro ao consultar o cupom ${error.message}`);
        setResType("warning");
      }
    };
    loadProduct();
    return () => {
      setProduct({ ...initialProducts });
      setProductError({ ...initialProducts });
    };
  }, [currentItem, initialProducts, API, endpoints]);

  const handleSubmit = async () => {
    setLoading(true);
    const newUniqueId = uuidv4();
    try {
      await schema.validate(product, { abortEarly: false });
      try {
        let error, data;

        if (!currentItem) {
     
            product.code = `CMS-${newUniqueId}`;

          ({ error, data } = await API.create(endpoints.PRODUCTS, product));
          if (addItem && data) {
            addItem(data);
          }
       
        } else   {
          ({ error, data } = await API.update(endpoints.PRODUCTS, product, {
            id: currentItem.id,
          }));
            if(addItem && data) updateItem(data)
        }
        if (error) {
          throw new Error(error);
        }
        setResMessage('Registrado com sucesso!');
        setResType("success");
        setProduct({ ...initialProducts });
        setProductError({ ...initialProducts });
        setLoading(false);
      } catch (error: any) {
        setResMessage(error.message);
        setResType("warning");
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
      setLoading(false);
      if (error instanceof Yup.ValidationError) {

        error.inner.forEach(({ path, message }) => {
          console.log(message)
          setProductError((state) => ({ ...state, [path as string]: message }));
        });
      }
    }
  };
  return (
    <Modal
    open={open}
    disableEnforceFocus
    onClose={()=>closeModal(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
      {!currentItem ? 'Registrar Produto' : 'Atualizar Produto'}  
      </Typography>
      {Boolean(resMessage) && (
          <Alert
            severity={resType === "success" ? "success" : "warning"}
            onClose={() => setResMessage("")}
          >
            {resMessage}
          </Alert>
        )}
        <TextField
          required
          onChange={handleChange}
          error={Boolean(productError.name)}
          id="name"
          label="Nome do Produto: "
          helperText={productError.name}
          variant="standard"
          value={product.name}
          fullWidth
        />
        <TextField
          required
          onChange={handleChange}
          error={Boolean(productError.link)}
          id="link"
          label="Link do Produto: "
          helperText={productError.link}
          variant="standard"
          value={product.link}
          fullWidth
        />
        <TextField
          required
          onChange={handleChange}
          error={Boolean(productError.image)}
          id="image"
          label="Link da Imagem do Produto:"
          helperText={productError.image}
          variant="standard"
          value={product.image}
          fullWidth
        />
      
      <TextField
          
          onChange={handleChange}
          error={Boolean(productError.originalPrice)}
          id="originalPrice"
          InputProps={{ inputProps: { min: 36, max: 40 } }}
          label="Valor Original do Produto:"
          helperText={productError.originalPrice}
          variant="standard"
          value={product.originalPrice}
          type="number"
          fullWidth
        />
        <TextField
          required
          onChange={handleChange}
          error={Boolean(productError.actualPrice)}
          id="actualPrice"
          label="Valor com Desconto do Produto:"
          helperText={productError.actualPrice}
          variant="standard"
          value={product.actualPrice }
          type="number"
          fullWidth
        />
        <TextField
          onChange={handleChange}
          error={Boolean(productError.installmentPrice)}
          id="installmentPrice"
          label="Valor das Parcelas:"
          helperText={productError.installmentPrice}
          variant="standard"
          value={product.installmentPrice}
          type="number"
          fullWidth
        />
        <TextField
          onChange={handleChange}
          error={Boolean(productError.installmentNro)}
          id="installmentNro"
          label="Numero de Parcelas:"
          helperText={productError.installmentNro}
          variant="standard"
          value={product.installmentNro}
          fullWidth
        />
        {/* <TextField
          onChange={handleChange}
          error={Boolean(productError.discount)}
          id="discount"
          label="Desconto:"
          helperText={productError.discount}
          variant="standard"
          value={product.discount}
          fullWidth
        /> */}
          <TextField
          required
          select
          onChange={handleChange}
          error={Boolean(productError.sellerId)}
          id="sellerId"
          name="sellerId"
          label="Anunciante:"
          helperText={productError.sellerId}
          variant="standard"
          value={product.sellerId}
          fullWidth

        >
          {allTypes.length > 0 &&
            allSelles.map((option: OptionSeller) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </TextField>
        <Divider/>
          <Button
            variant="contained"
            onClick={handleSubmit}
            fullWidth
            disableElevation
          >
            {!currentItem ? 'Registrar' : 'Atualizar'} 
          </Button>
    </Box>
  </Modal>
  );
}

export default ModalProducts;