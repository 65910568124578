import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import MenuIcon from "@mui/icons-material/Menu";
import Apartment from "@mui/icons-material/Apartment";
import ConfirmationNumber from "@mui/icons-material/ConfirmationNumber";
import Campaign from "@mui/icons-material/Campaign";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import Home from "@mui/icons-material/Home";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Outlet, Link } from "react-router-dom";
import { Container } from "@mui/material";
import API, { endpoints } from "../services/api/API";

const drawerWidth = 240;

interface Props {
  window?: () => Window;
}

export default function ResponsiveDrawer(props: Props) {
  const location = useLocation();

  const { window } = props;
  const [open, setOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = React.useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [userData, setUserData] = useState<{ name?: string }>({});

  const fetchUserdata = async () => {
    const { error, data } = await API.list(endpoints.ME);
    if (!error) {
      setUserData(data);
    }
  };

  useEffect(() => {
    const actualPath = location.pathname;
    if (!actualPath.includes("unauthorized")) {
      fetchUserdata();
    }
  }, [location.pathname]);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setOpen(!open);
    }
  };

  const handleClickSubmenu = () => {
    setOpenSubmenu(!openSubmenu);
  };

  const allIcons = [<Home />, <Apartment />, <ConfirmationNumber />, <Campaign />, <ProductionQuantityLimitsIcon />];

  type TranslationMenu = {
    [routeName:string]:string
  }

  const translationMenu:TranslationMenu={
    "Home":"Inicio", 
    "Sellers":"Anunciantes", 
    //"Coupons":"Cupons", 
    "Campaigns":"Campanhas", 
    //"Products":"Produtos"
  }

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {Object.keys(translationMenu).map((key, index) => 
          <Link key={key} to={index === 0 ? `/` : `${key.toLowerCase()}`}>
            <ListItem key={key} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {allIcons[index]}
                </ListItemIcon>
                <ListItemText primary={translationMenu[key]} />
              </ListItemButton>
            </ListItem>
          </Link>
        )}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Globo Shoppable ADMIN
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar>{userData.name ? userData.name.charAt(0) : "U"}</Avatar>
            <Typography variant="body1" sx={{ ml: 1 }}>
              {userData.name || "User"}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: `calc(100% - ${drawerWidth}px)` }}
      >
        <Toolbar />
        <Container maxWidth="sm">
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}
